const menuList = [
  // {
  //   path: '/show',
  //   name: 'show',
  //   meta: {
  //     title: "风潮由我艺术展",
  //     menuPath: "/show",
  //   },
  //   component: () => import("../views/Show.vue"),

  // },
  {
    path: "/contestDetail",
    name: "contestDetail",
    meta: {
      title: "大赛首页",
      menuPath: "/contestDetail",
    },
    component: () => import("../views/contestDetail.vue"),
  },
  {
    path: "/exhibition",
    name: "exhibition",
    meta: {
      title: "数字艺术展",
      menuPath: "/exhibition",
    },
    component: () => import("../views/exhibition.vue"),
  },
  {
    path: "/awardTour",
    name: "awardTour",
    meta: {
      title: "获奖巡展",
      menuPath: "/awardTour",
    },
    component: () => import("../views/awardTour.vue"),
  },
  {
    path: "/designReward",
    name: "designReward",
    meta: {
      title: "获奖公示",
      menuPath: "/designReward",
    },
    component: () => import("../views/designReward.vue"),
  },
  {
    path: "/contestTips",
    name: "contestTips",
    meta: {
      title: "参赛须知",
      menuPath: "/contestTips",
    },
    component: () => import("../views/contestTips.vue"),
  },


  {
    path: "/contestWorks",
    name: "contestWorks",
    meta: {
      title: "大赛作品",
      menuPath: "/contestWorks",
    },
    component: () => import("../views/contestWorks.vue"),
  },
  // {
  //   path: "/subVenue",
  //   name: "subVenue",
  //   meta: {
  //     title: "大赛分会场",
  //     menuPath: "/subVenue",
  //   },
  //   component: () => import("../views/subVenue.vue"),
  // },
  {
    path: "/issues",
    name: "issues",
    meta: {
      title: "常见问题",
      menuPath: "/tissues",
    },
    component: () => import("../views/issues.vue"),
  },
];

export default menuList;
