<template>
  <div id="app">
    <keep-alive>
      <router-view
        v-if="$route.meta.keepAlive"
        :breadList="breadList"
      ></router-view>
    </keep-alive>
    <router-view
      v-if="!$route.meta.keepAlive"
      :breadList="breadList"
    ></router-view>
  </div>
</template>

<script>

export default {
  name: "App",
  data() {
    return {
      breadList: [],
    };
  },
};
</script>

<style lang="less">
@import url("./assets/style/public.less");
</style>
