import { createRouter, createWebHistory } from "vue-router";
import routerList from "./routerList";
import baseInfo from "../utils/config";

const router = createRouter({
  history: createWebHistory(),
  routes: routerList,
});

//路由守卫全局钩子 进入页面时候修改页面title
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = baseInfo.BASE_TITLE + "-" + to.meta.title;
  }

  if (!(localStorage.userInfo && localStorage.token)) {
    if (to.path === '/myWorks') {

      next({ path: '/signUp' })
    }
    if (to.path === '/uploadFile') {
      next({ path: '/signUp' })
    }
  } else {
    if (to.path === '/signUp') {
      next({ path: '/myWorks' })
    }
  }
  next();
});
export default router;
