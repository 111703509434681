import { createStore } from "vuex";

export default createStore({
  state: {
    hasLogin: !!localStorage.getItem('token')
  },
  mutations: {
    changeHasLogin(state,bol) {
      state.hasLogin = bol
    }
  },
  actions: {},
  modules: {},
});
