import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import "element-plus/lib/theme-chalk/index.css";
import "./style/theme/index.css";
import "babel-polyfill";

//将$message挂载到this上
const app = createApp(App);
app.use(store).use(router).use(ElementPlus).mount("#app");
