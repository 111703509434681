const Home = () => import("../views/Home.vue");
import childrenList from "./menuList";
const children = [
  {
    path: "/signUp",
    name: "signUp",
    meta: {
      title: "大赛报名",
      menuPath: "",
    },
    component: () => import("../views/signUp.vue"),
  },
  // 个人中心
  {
    path: "/myWorks",
    name: "myWorks",
    meta: {
      title: "个人中心",
      menuPath: ""
    },
    component: () => import("../views/myWorks.vue")
  },
  /**
   *主题详情-平面
   */
  {
    path: "/contestWorks/imageWorkDetail",
    name: "contestWorksImageWorkDetail",
    meta: {
      title: "作品详情",
      menuPath: "/contestWorks",
    },
    component: () => import("../views/imageWorkDetail.vue"),
  },

    /**
   *主题详情-视频
   */ {
    path: "/contestWorks/videoWorkDetail",
    name: "contestWorksVideoWorkDetail",
    meta: {
      title: "作品详情",
      menuPath: "/contestWorks",
    },
    component: () => import("../views/videoWorkDetail.vue"),
  },
  /**
 *我的作品详情-平面
 */
  {
    path: "/myWorks/imageWorkDetail",
    name: "myWorksImageWorkDetail",
    meta: {
      title: "作品详情",
      menuPath: "/myWorks",
    },
    component: () => import("../views/imageWorkDetail.vue"),
  },
  /**
 *我的作品详情-视频
 */
  {
    path: "/myWorks/videoWorkDetail",
    name: "myWorksVideoWorkDetail",
    meta: {
      title: "作品详情",
      menuPath: "/myWorks",
    },
    component: () => import("../views/videoWorkDetail.vue"),
  },
  //上传作品
  {
    path: "/upLoadFile",
    name: "upLoadFile",
    meta: {
      title: "上传作品",
      menuPath: "/myWorks",
    },
    component: () => import("../views/upLoadFile.vue"),
  },
  {
    path: "/subWorks",
    name: "subWorks",
    meta: {
      title: "大赛作品",
      menuPath: "/subWorks",
    },
    component: () => import("../views/subWorks.vue"),
  },
  // 获奖公示 获奖作品 详情
  {
    path: "/designReward/imageWorkDetail",
    name: "designRewardImageWorkDetail",
    meta: {
      title: "获奖作品详情",
      menuPath: "/designReward",
    },
    component: () => import("../views/imageWorkDetail.vue"),
  },
  // 获奖公示 获奖作品 视频详情
  {
    path: "/designReward/videoWorkDetail",
    name: "designRewardVideoWorkDetail",
    meta: {
      title: "获奖作品详情",
      menuPath: "/designReward",
    },
    component: () => import("../views/videoWorkDetail.vue"),
  },
  // 获奖巡展
  {
    path: "/awardTour",
    name: "awardTour",
    meta: {
      title: "获奖巡展",
      menuPath: "/awardTour",
    },
    component: () => import("../views/awardTour.vue"),
  },
  // 艺术展作品详情页
  {
    path: "/exhibitionWorkDetail",
    name: "exhibitionWorkDetail",
    meta: {
      title: "获奖巡展",
      menuPath: "/exhibitionWorkDetail",
    },
    component: () => import("../views/exhibitionWorkDetail.vue"),
  }
];
const routerList = [
  {
    path: "/",
    name: "Home",
    component: Home,
    redirect: '/contestDetail',
    meta: {
      keepAlive: false,
    },
    children: [...childrenList, ...children],
  },

  {
    path: '/notfound',
    name: 'notfound',
    component: () => import("../views/NotFound.vue"),

  },
  {
    path: '/:pathMatch(.*)',
    redirect: '/notfound',
  },
];

export default routerList;
