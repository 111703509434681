const baseInfo = {
  BASE_TITLE: "明基设计大赛",
  COMPANY_TITLE: "",
  COPY_RIGHT: "",
  LOGIN_OUT_TIME: 3600 * 3 * 1000,
  GET_TOKEN_AGIN: 3600 * 5 * 1000,
  SCOKET_HOST: "",
  BASE_URL: "",
  // MOBILE_URL:'http://118.31.58.181/'//测试
  MOBILE_URL:'https://designvue.benq.com.cn/'//正式
};
export default baseInfo;
